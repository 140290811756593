<template>
  <span class="v-select-arrow" />
</template>

<script>
export default {
  name: 'CustomeArrow'
}
</script>

<style lang="scss" scoped>
.v-select-arrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-top: 15px solid $border_01;
}
</style>
