export const PATH = {
  WORKER_RECRUITMENTS: '/worker/recruitments',
  WORKER_RECRUITMENT_APPLY: '/worker/recruitment-apply-by-worker',
  WORKER_EVALUATIONS: '/worker/evaluations',
  WORKER_JOB_APPLY: '/worker/job-application',
  EVALUATION_RECORD: '/evaluation-record',
  WORKER_CANCEL_JOB_APPLY: '/worker/cancel-job-application',
  WORKER_LOGIN: '/worker/login',
  WORKER_LOGOUT: '/worker/logout',
  WORKER_REGISTER: '/worker/register',
  AUTH_FACEBOOK: '/auth/facebook',
  DASHBOARD: '/worker/dashboard',
  PROFILE: '/worker/profile',
  CONTACT: '/worker/contact-us',
  FORGOT_PASSWORD: '/worker/password/forgot-password',
  PASSWORD_RESET: '/worker/password/reset'
}
