export default {
  data: () => {
    return {
      heightScrollbar: 0,
      widthScrollbar: 0
    }
  },
  methods: {
    updateHeightScrollbar() {
      this.heightScrollbar = window.innerHeight
      this.widthScrollbar = window.innerWidth
    }
  },
  mounted() {
    this.updateHeightScrollbar()
    window.addEventListener('resize', this.updateHeightScrollbar)
  },
  destroyed() {
    window.removeEventListener('resize', this.updateHeightScrollbar)
  }
}
