import axios from 'axios'
import interceptors from './interceptors'

const Api = axios.create({
  baseURL:
    process.env.NODE_ENV !== 'production'
      ? process.env.VUE_APP_BASE_URL
      : process.env.VUE_APP_PROD_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

interceptors.setupRequest(Api)
interceptors.setupResponse(Api)

export default Api
