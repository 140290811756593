const showSpinner = ({ commit }) => {
  commit('SHOW_SPINNER')
}
const hideSpinner = ({ commit }) => {
  commit('HIDE_SPINNER')
}

export default {
  showSpinner,
  hideSpinner
}
