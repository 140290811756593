const token = (state) => {
  return (
    state.token ||
    localStorage.getItem('token') ||
    sessionStorage.getItem('token')
  )
}

const userId = (state) => {
  const { user = {} } = state
  return (
    user.userId ||
    localStorage.getItem('user_id') ||
    sessionStorage.getItem('user_id')
  )
}

const profile = (state) => state.user || {}

const infoDashboard = (state) => state.dashboard || {}

export default {
  token,
  userId,
  profile,
  infoDashboard
}
