// const exampleData = [
//   {
//     id: '1',
//     name: '岐阜で原稿手配をパソコンで行う簡単なお仕事です',
//     username: '株式会社フルブリッジ',
//     startDate: '5月10日 ',
//     endDate: '10月10日',
//     startTime: '9:00',
//     endTime: '18:00',
//     address: '岐阜県岐阜市長良福田町1-30',
//     thumbnailImage:
//       'https://www.worldhumanitarianday.org/sites/default/files/styles/small/public/2022-08/Main%20image_The%20Counsellor.jpg'
//   }
// ]

const recruitmentList = (state) => state.recruitments || []

const recruitmentApplyList = (state) => state.recruitmentsApply || []

const recruitmentsEstablishList = (state) => state.recruitmentsEstablish || []

const recruitmentsCompleteList = (state) => state.recruitmentsComplete || []

const querySearchRecruitment = (state) => state.querySearchRecruitment

const recruitmentDetail = (state) => state.recruitmentDetail

const evaluation = (state) => state.evaluation

export default {
  recruitmentList,
  recruitmentApplyList,
  recruitmentsEstablishList,
  recruitmentsCompleteList,
  querySearchRecruitment,
  recruitmentDetail,
  evaluation
}
