const CLEAR_TOKEN = (state) => {
  state.user = {}
  state.token = null
  localStorage.removeItem('token')
  localStorage.removeItem('user_id')
}

const LOGIN = (state, payload) => {
  const { token = '', user = {} } = payload
  state.user = user
  localStorage.setItem('token', token)
  localStorage.setItem('user_id', user.userId)
  state.token = token
}

const UPDATE_USER = (state, payload) => {
  state.user = Object.assign({}, state.user, payload)
}

const UPDATE_DASHBOARD = (state, payload) => {
  state.dashboard = payload
}

export default {
  CLEAR_TOKEN,
  LOGIN,
  UPDATE_DASHBOARD,
  UPDATE_USER
}
