<template>
  <div
    :style="`height: ${heightScrollbar}px; width: ${widthScrollbar}px; overflow: auto`"
    id="main-element"
  >
    <router-view />
    <Spinner :loading="isLoading" />
  </div>
</template>

<script>
import baseScrollbar from '@/mixins/baseScrollbar'
import Spinner from '@/components/Spinner'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  mixins: [baseScrollbar],
  components: {
    Spinner
  },
  data: () => {
    return {
      currentPath: ''
    }
  },
  computed: {
    ...mapGetters('layout', ['isLoading'])
  },
  mounted() {
    this.$root.$on('scrollToTop', () => {
      this.scrollTotop()
    })
  },
  updated() {
    if (this.currentPath !== window.location.pathname) {
      this.scrollTotop()
      this.currentPath = window.location.pathname
    }
  },
  methods: {
    scrollTotop() {
      const element = document.getElementById('main-element')
      if (element)
        element.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
    }
  }
}
</script>
