import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const initState = {
  user: {},
  token: null,
  dashboard: null
}

export default {
  namespaced: true,
  state: () => initState,
  mutations,
  actions,
  getters
}
