<template>
  <div class="spinner" :class="{ 'spinner--show': loading }">
    <!-- <BounceLoader :loading="loading" :color="color" :size="size" /> -->
    <div v-if="loading" class="balls-wrapper">
      <div class="ball" />
      <div class="ball" />
      <div class="ball" />
      <div class="ball" />
      <div class="ball" />
    </div>
  </div>
</template>

<script>
// import BounceLoader from 'vue-spinner/src/BounceLoader.vue'

export default {
  name: 'Spinner',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    // BounceLoader
  },
  data: () => {
    return {
      color: '#1a73e8',
      size: '80px'
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include flex-center;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  z-index: $index_spinner;
  overflow: hidden;

  &.spinner--show {
    opacity: 1;
    visibility: visible;
  }
}

.balls-wrapper {
  --wrapper-width: 160px;
  width: var(--wrapper-width);
  height: var(--wrapper-width);
  padding: 20px 10px 20px 20px;
  @include flex-center-vert;
  justify-content: space-between;
  border-radius: 50%;
  background-color: rgba(#fff, 0.9);

  .ball {
    --ball-size: 20px;
    --duration: 2s;
    --origin: -80px;
    --gap: calc((var(--wrapper-width) - var(--ball-size) * 5) / 4);
    --move-ball: calc((var(--ball-size) + var(--gap)) * -1);
    height: var(--ball-size);
    width: var(--ball-size);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 50%;

    &:nth-child(1) {
      background-color: hsl(0, 83%, 63%);
      animation: ball1 var(--duration) linear infinite alternate;
    }

    &:nth-child(2) {
      background-color: hsl(36, 72%, 55%);
      animation: ball2 var(--duration) linear infinite alternate;
    }

    &:nth-child(3) {
      background-color: hsl(194, 90%, 60%);
      animation: ball3 var(--duration) linear infinite alternate;
    }

    &:nth-child(4) {
      background-color: hsl(163, 58%, 59%);
      animation: ball4 var(--duration) linear infinite alternate;
    }

    &:nth-child(5) {
      background-color: hsl(0, 0%, 100%);
      animation: ball5 var(--duration) linear infinite alternate;
    }

    @keyframes ball1 {
      0% {
        transform: translate(0);
      }

      100% {
        transform: translateX(
          calc(var(--wrapper-width) - var(--ball-size) - 30px)
        );
      }
    }

    @keyframes ball2 {
      0% {
        transform-origin: -80px;
        transform: translateX(0) rotate(0);
      }

      25%,
      100% {
        transform-origin: center;
        transform: translateX(var(--move-ball)) rotate(-90deg);
      }
    }

    @keyframes ball3 {
      0%,
      25% {
        transform-origin: -80px;
        transform: translateX(0) rotate(0);
      }

      50%,
      100% {
        transform-origin: center;
        transform: translateX(var(--move-ball)) rotate(-90deg);
      }
    }

    @keyframes ball4 {
      0%,
      50% {
        transform-origin: -80px;
        transform: translateX(0) rotate(0);
      }

      75%,
      100% {
        transform-origin: center;
        transform: translateX(var(--move-ball)) rotate(-90deg);
      }
    }

    @keyframes ball5 {
      0%,
      75% {
        transform-origin: -80px;
        transform: translateX(0) rotate(0);
      }

      100% {
        transform-origin: center;
        transform: translateX(var(--move-ball)) rotate(-90deg);
      }
    }
  }
}
</style>
