import Vue from 'vue'
import App from './App.vue'
import CustomeArrow from '@/components/select/CustomeArrow'
import router from './router'
import store from './store'
import VeeValidate, { Validator } from 'vee-validate'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import PortalVue from 'portal-vue'
import Toasted from 'vue-toasted'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import './styles/styles.scss'
import Fragment from 'vue-fragment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import './filters'
import VueYoutube from 'vue-youtube'

const toastOptions = {
  theme: 'outline',
  duration: 5000,
  position: 'top-center',
  fitToScreen: true
}

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: (createElement) => createElement(CustomeArrow)
  }
})

Validator.localize('en', {
  messages: {
    required: 'このフィールドは必須項目です',
    min: (_, { length }) =>
      `このフィールドには少なくとも ${length} 文字が必要です`,
    max: (_, { length }) =>
      `このフィールドは ${length} 文字以下でなければなりません`,
    email: 'メールアドレスが無効です',
    numeric: 'このフィールドには数字のみ使用できます',
    confirmed: '確認パスワードが一致しません'
  }
})

Validator.extend('password', {
  getMessage: () =>
    '※パスワードは8～20文字で、大文字、小文字、数字を含めるようにする',
  validate: (value) => {
    const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$')
    return regex.test(value)
  }
})

Vue.component('v-select', vSelect)
Vue.use(VueYoutube)
Vue.use(Fragment.Plugin)
Vue.use(PerfectScrollbar)
Vue.use(Toasted, toastOptions)
Vue.use(DatePicker)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields'
})
Vue.use(PortalVue)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
