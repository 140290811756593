import Api from './api'
import { PATH } from './endPoints'

const getRecruitments = async (params) => {
  return Api.get(PATH.WORKER_RECRUITMENTS, { params: params }).then(
    (res) => res.data
  )
}

const getMyRecruitments = async (params) => {
  return Api.get(PATH.WORKER_RECRUITMENT_APPLY, {
    params: params
  }).then((res) => res.data)
}

const getRecruitmentDetail = async (params) => {
  const { id } = params
  delete params.id
  return Api.get(`${PATH.WORKER_RECRUITMENTS}/${id}`, { params: params }).then(
    (res) => res.data
  )
}

const getRecruitmentRate = async (params) => {
  const { id } = params
  delete params.id
  return Api.get(`${PATH.WORKER_EVALUATIONS}/${id}`, { params: params }).then(
    (res) => res.data
  )
}

const createRecruitmentRate = async (params) => {
  return Api.post(PATH.WORKER_EVALUATIONS, params).then((res) => res.data)
}

const updateRecruitmentRate = async (params) => {
  return Api.put(PATH.WORKER_EVALUATIONS, params).then((res) => res.data)
}

const applyRecruitment = async (params) => {
  return Api.post('/worker/job-application', params).then((res) => res.data)
}

const getEvaluation = async (params) => {
  const { id } = params
  delete params.id
  return Api.get(`${PATH.EVALUATION_RECORD}/${id}`, { params: params }).then(
    (res) => res.data
  )
}

const addEvaluation = async (params) => {
  return Api.post(PATH.EVALUATION_RECORD, params).then((res) => res.data)
}

const cancelJobApplication = async (id) => {
  return Api.delete(`${PATH.WORKER_CANCEL_JOB_APPLY}/${id}`).then(
    (res) => res.data
  )
}

const updateJobApply = async (params) => {
  const { id, data } = params
  return Api.put(`${PATH.WORKER_JOB_APPLY}/${id}`, data).then((res) => res.data)
}

export default {
  getRecruitments,
  getMyRecruitments,
  getRecruitmentDetail,
  applyRecruitment,
  getEvaluation,
  addEvaluation,
  cancelJobApplication,
  getRecruitmentRate,
  createRecruitmentRate,
  updateRecruitmentRate,
  updateJobApply
}
