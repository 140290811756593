import Vue from 'vue'
import Vuex, { createLogger } from 'vuex'

import user from './modules/user'
import layout from './modules/layout'
import recruitment from './modules/recruitment'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    user,
    layout,
    recruitment
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
