const RECRUITMENT_UPDATED = (state, payload) => {
  const { data = [] } = payload
  state.recruitments = data
}

const MY_RECRUITMENT_UPDATED = (state, payload) => {
  const { type, obj: { data = [] } = {} } = payload
  switch (type) {
    case 'apply':
      state.recruitmentsApply = data
      break
    case 'establish':
      state.recruitmentsEstablish = data
      break
    case 'complete':
      state.recruitmentsComplete = data
      break
    default:
      break
  }
}

const UPDATE_QUERY_SEARCH_RECRUITMENT = (state, payload) => {
  state.querySearchRecruitment = { ...state.querySearchRecruitment, ...payload }
}

const UPDATE_RECRUITMENT_DETAIL = (state, payload) => {
  state.recruitmentDetail = { ...payload }
}

const UPDATE_EVALUATION = (state, payload) => {
  state.evaluation = [...payload.data]
}

const ADD_EVALUATION = (state) => {
  state.recruitmentDetail = { ...state.recruitmentDetail, hadEvaluation: true }
}

export default {
  RECRUITMENT_UPDATED,
  MY_RECRUITMENT_UPDATED,
  UPDATE_QUERY_SEARCH_RECRUITMENT,
  UPDATE_RECRUITMENT_DETAIL,
  UPDATE_EVALUATION,
  ADD_EVALUATION
}
