const SHOW_SPINNER = (state) => {
  state.loading = state.loading + 1
}

const HIDE_SPINNER = (state) => {
  if (state.loading < 0) {
    state.loading = 0
    return
  }
  state.loading = state.loading - 1
}

export default {
  SHOW_SPINNER,
  HIDE_SPINNER
}
