export const RecruitmentTypes = Object.freeze({
  APPLIED: 'applied',
  CONSTRACTED: 'contracted',
  ACCOMPLISHED: 'accomplished'
})

export const DateUnits = Object.freeze({
  DAY: '日',
  MONTH: '月',
  YEAR: '年',
  HOUR: '時',
  MINUTE: '分'
})

export const getValidData = (d) => {
  let result
  const unitsList = Object.keys(DateUnits).map((i) => DateUnits[i])

  if (!d?.label && unitsList?.includes?.(d?.[d?.length - 1]))
    result = d.slice(0, d.length - 1)
  if (d?.label) result = d.value

  return result
}

export const genders = ['男', '女', 'その他']

export const LeftMenus = [
  {
    to: 'profile',
    activating: 'profile',
    label: 'マイページ'
  },
  {
    to: 'recruitments',
    activating: 'recruitments',
    label: '依頼一覧'
  },
  {
    to: 'application_list',
    activating: 'history',
    label: '依頼履歴一覧'
  },
  {
    to: 'contact',
    activating: 'contact',
    label: 'お問い合わせ'
  }
]

export const PageModes = {
  EDIT: 'edit',
  READ: 'read'
}

export const ResponseTypes = Object.freeze({
  SUCCESS: 'success',
  ERROR: 'error'
})

export const addressTypes = Object.freeze({
  typeAddress: '場所を指定する',
  companyAddress: '誠進産業社屋',
  adminAddress: '管理者より指定'
})

export const routePathNames = Object.freeze({
  layout: 'layout',
  dashboard: 'dashboard',
  contact: 'contact',
  profile: 'profile',
  login: 'login',
  register: 'register',
  policy: 'policy',
  resetPassword: 'reset_password',
  registerSuccess: 'register_success',
  recruitments: 'recruitments',
  recruitmentDetail: 'recruitment_detail',
  estimateRecruitment: 'estimate_recruitment',
  history: 'history',
  applicationList: 'application_list',
  establishList: 'establish_list',
  completeList: 'complete_list',
  historyRecruitmentDetail: 'history_recruitment_detail'
})
