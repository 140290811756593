import store from '@/store'
import camelcaseKeys from 'camelcase-keys'

function setupRequest(instance) {
  instance.interceptors.request.use(
    (request) => {
      const token = store.getters['user/token']
      if (token) {
        request.headers.Authorization = `Bearer ${token}`
      }

      !request._skipSpinner && store.dispatch('layout/showSpinner')
      return request
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

function setupResponse(instance) {
  instance.interceptors.response.use(
    (response) => {
      if (response.headers['content-type'].startsWith('application/json')) {
        response.data = camelcaseKeys(response.data, { deep: true })
      }

      setTimeout(() => {
        store.dispatch('layout/hideSpinner')
      }, 300)
      return response
    },
    (error) => {
      const token = store.getters['user/token']
      if (
        error.response.status === 401 &&
        !!token &&
        !window.location.pathname.includes('login')
      ) {
        store.dispatch('user/clear')
        window.location.reload()
      }

      setTimeout(() => {
        store.dispatch('layout/hideSpinner')
      }, 300)
      return Promise.reject(error)
    }
  )
}

export default {
  setupRequest,
  setupResponse
}
